var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-custom"},[_c('header',{staticClass:"modal-header py25 px65 fs28 serif weight-700 cl-white bg-cl-main"},[_vm._v("\n    "+_vm._s(_vm.$t('Log in'))+"\n    "),_c('i',{staticClass:"pointer icon-zamknij cl-bg-tertiary",attrs:{"slot":"close"},on:{"click":_vm.close},slot:"close"})]),_vm._v(" "),(_vm.hasRedirect)?_c('div',{staticClass:"pt10 pb10 px65 redirect-error"},[_c('p',{staticClass:"h5 mb0 mt0"},[_vm._v("\n      "+_vm._s(_vm.$t('You need to be logged in to see this page'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-content bg-cl-main-smoke pt30 pb60 px65 cl-secondary"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('base-input',{staticClass:"mb10",attrs:{"type":"email","name":"email","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validations":[{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: _vm.$t('Field is required.')
        }]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('div',{staticClass:"row middle-xs m0 mb15"},[_c('base-checkbox',{staticClass:"col-xs-7 col-sm-6 remember-checkbox",attrs:{"id":"remember"},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}},[_vm._v("\n          "+_vm._s(_vm.$t('Remember me'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"col-xs-5 col-sm-6 flex end-xs middle-xs fs16"},[_c('a',{staticClass:"cl-main hidden-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword($event)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Forgot the password?'))+"\n          ")])])],1),_vm._v(" "),_c('div',{staticClass:"row m0 middle-xs between-xs"},[_c('a',{staticClass:"col-xs-5 p0 h6 light-gray visible-xs mb20",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Forgot the password?'))+"\n        ")]),_vm._v(" "),_c('button-full',{staticClass:"login-btn fs16",attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n          "+_vm._s(_vm.$t('Log in'))+" ")])],1),_vm._v(" "),_c('div',{staticClass:"center-xs"},[_vm._v("\n        "+_vm._s(_vm.$t('or'))+"\n        "),_c('a',{staticClass:"registerLink",attrs:{"href":"#","data-testid":"registerLink"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('register an account'))+"\n        ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }