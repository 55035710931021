<template>
  <div class="login-custom">
    <header class="modal-header py25 px65 fs28 serif weight-700 cl-white bg-cl-main">
      {{ $t('Log in') }}
      <i
        slot="close"
        class="pointer icon-zamknij cl-bg-tertiary"
        @click="close"
      />
    </header>
    <div v-if="hasRedirect" class="pt10 pb10 px65 redirect-error">
      <p class="h5 mb0 mt0">
        {{ $t('You need to be logged in to see this page') }}
      </p>
    </div>
    <div class="modal-content bg-cl-main-smoke pt30 pb60 px65 cl-secondary">
      <form @submit.prevent="login" novalidate>
        <base-input
          class="mb10"
          type="email"
          name="email"
          v-model="email"
          @blur="$v.email.$touch()"
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <base-input
          class="mb10"
          type="password"
          name="password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[{
            condition: !$v.password.required && $v.password.$error,
            text: $t('Field is required.')
          }]"
        />
        <div class="row middle-xs m0 mb15">
          <base-checkbox
            class="col-xs-7 col-sm-6 remember-checkbox"
            id="remember"
            v-model="remember"
          >
            {{ $t('Remember me') }}
          </base-checkbox>
          <div class="col-xs-5 col-sm-6 flex end-xs middle-xs fs16">
            <a class="cl-main hidden-xs" href="#" @click.prevent="remindPassword">
              {{ $t('Forgot the password?') }}
            </a>
          </div>
        </div>
        <div class="row m0 middle-xs between-xs">
          <a class="col-xs-5 p0 h6 light-gray visible-xs mb20" href="#" @click.prevent="remindPassword">
            {{ $t('Forgot the password?') }}
          </a>
          <button-full class="login-btn fs16" type="submit" data-testid="loginSubmit">
            {{ $t('Log in') }} <!-- Log in to your account -->
          </button-full>
        </div>
        <div class="center-xs">
          {{ $t('or') }}
          <a class="registerLink" href="#" @click.prevent="switchElem" data-testid="registerLink">
            {{ $t('register an account') }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Login from '@vue-storefront/core/compatibility/components/blocks/Auth/Login'

import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from '../Form/BaseCheckbox.vue'
import BaseInput from '../Form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

export default {
  mixins: [Login],
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  },
  data () {
    return {
      hasRedirect: !!localStorage.getItem('redirect')
    }
  },
  methods: {
    close (e) {
      if (e) localStorage.removeItem('redirect')
      this.$bus.$emit('modal-hide', 'modal-signup')
    },
    login () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Please fix the validation errors'),
          action1: { label: this.$t('OK') }
        })
        return
      }
      this.callLogin()
    },
    remindPassword () {
      if (!(typeof navigator !== 'undefined' && navigator.onLine)) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: this.$t('Reset password feature does not work while offline!'),
          action1: { label: this.$t('OK') }
        })
      } else {
        this.callForgotPassword()
      }
    },
    onSuccess () {
      Vue.gtm.trackEvent({
        event: 'usercomevent',
        eventCategory: 'user',
        eventAction: 'login',
        email: this.email,
        firstName: this.currentUser ? this.currentUser.firstname : '',
        lastName: this.currentUser ? this.currentUser.lastname : ''
      })
      this.$store.dispatch('wishlist/load', true)
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      })
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: 'Zawartość koszyka została zsynchronizowana z twoim kontem.',
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    })
  },
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  }
}
</script>

<style lang="scss">
  .login-custom {
    input {
      border-color: white;
    }
  }
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-error: color(error);
$white: color(white);
$main: color(main);

  .fs28 {
    font-size: 28px;
  }
  .login-btn {
    margin-bottom: 20px;
    width: 100%;
    @media (max-width: 767px) {
      width: 55%;
      padding: 10px 25px;
      font-size: 16px;
    }
  }
  .remember-checkbox {
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .registerLink {
    color: $main;
    &:hover {
      text-decoration: underline;
    }
  }
  .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .modal-content {
    @media (max-width: 400px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .redirect-error {
    background-color: $color-error;
    color: $white;
  }
</style>
